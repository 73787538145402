import React, { Component } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import uuid from 'uuid';
import Collapsible from 'react-collapsible';
import Overview from './Overview';
import { connect } from 'react-redux';
import './index.css';
import { getCustomerOrdersUrl, searchEcommerceProductsUrl } from '../helpers/Common.js';
import { getCustomerOrders, getSearchProducts } from '../actions/Conversation';
import { format } from 'timeago.js';
import googlePlayBadge from '../public/logos/google-play-badge.png';
import appStoreBadge from '../public/logos/app-store-badge.png';
import search from '../public/icons/search.png';
import { months } from '../constants';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: <Overview />,
      ecommerceVariable: 'Select',
      showSearch: false,
      searchText: '',
      curUser: null,
      cartOpening: false,
      visitsOpening: false,
      ordersOpening: false,
      searchOrder: '',
      searchCart: '',
      openCart: true,
      openVisit: true,
      openOrder: true
      // products: {},
      // orders: {}
    };
    this.showSearchBox = this.showSearchBox.bind(this);
    this.onHandleSelection = this.onHandleSelection.bind(this);
    this.isEcommercePresent = this.isEcommercePresent.bind(this);
    this.shouldDisplaySearch = this.shouldDisplaySearch.bind(this);
    this.getSearchProducts = this.getSearchProducts.bind(this);
    this.getCustomerOrders = this.getCustomerOrders.bind(this);
    this.shouldDisplayProducts = this.shouldDisplayProducts.bind(this);
    // this.shouldDisplayComponent = this.shouldDisplayComponent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.checkKey = this.checkKey.bind(this);
    this.handleCartClick = this.handleCartClick.bind(this);
    this.handleOrderClick = this.handleOrderClick.bind(this);
    this.handleVisitClick = this.handleVisitClick.bind(this);
  }
  onHandleSelection(component) {
    this.setState({ activeComponent: component });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.conversation !== this.props.conversation) {
      this.setState({
        showSearch: false,
        ecommerceVariable: 'Select',
        products: {},
        orders: {},
        searchText: ''
      });
    }
  }
  detailsField = (data, field) => {
    let icons = {
      phone: 'fa fa-phone-square',
      email: 'fa fa-envelope',
      name: 'fa fa-address-book',
      city: 'fa fa-map-marker'
    };
    if (data && field)
      return (
        <div className="user-info-row" key={uuid()}>
          <div className="user-info-icon">
            <i className={icons[field]}></i>
          </div>
          <span className="user-info-text">{data}</span>
        </div>
      );
    return null;
  };
  imageDisplay(conversation) {
    const details = [];
    if (conversation !== undefined && conversation !== null && conversation.data) {
      if (
        conversation.data.sender &&
        conversation.data.sender.details &&
        conversation.data.sender.details.name
      ) {
        details.push(
          <div className="profile-header" key={uuid()}>
            {this.detailsField(conversation.data.sender.details.email, 'email')}
          </div>
        );
      } else if (conversation.data.ecommerce && conversation.data.ecommerce.customer) {
        details.push(
          <div className="profile-header" key={uuid()}>
            {this.detailsField(conversation.data.ecommerce.customer.name, 'name')}
            {this.detailsField(conversation.data.ecommerce.customer.phone, 'phone')}
            {this.detailsField(conversation.data.ecommerce.customer.email, 'email')}
          </div>
        );
      }
      if (
        conversation.data.ecommerce &&
        conversation.data.ecommerce.customer &&
        conversation.data.ecommerce.customer.location
      ) {
        details.push(
          <div className="profile-header" key={uuid()}>
            {this.detailsField(conversation.data.ecommerce.customer.location, 'city')}
          </div>
        );
      }
    }
    return details;
  }
  showSearchBox(eventKey, e) {
    this.setState({
      showSearch: true,
      ecommerceVariable: e.target.title
    });
  }
  getCustomerOrders(eventKey, e) {
    this.setState({
      ecommerceVariable: e.target.title,
      showSearch: false
    });
    this.props.getCustomerOrders(getCustomerOrdersUrl(this.props.conversation.unique_id));
  }

  isEcommercePresent(conversation) {
    if (
      conversation !== undefined &&
      conversation.data !== undefined &&
      conversation.data.ecommerce !== undefined
    ) {
      return (
        <DropdownButton title={this.state.ecommerceVariable}>
          <Dropdown.Item eventKey={0} title="Search Products" onSelect={this.showSearchBox}>
            Search Products
          </Dropdown.Item>
          <Dropdown.Item
            eventKey={1}
            title="Customer Orders"
            onSelect={this.getCustomerOrders}
            disabled={conversation.data.ecommerce.customer ? false : true}
          >
            Customer Orders
          </Dropdown.Item>
          <Dropdown.Item eventKey={2} disabled="true" title="Search Customers">
            Search Customers
          </Dropdown.Item>
        </DropdownButton>
      );
    }
    return (
      <React.Fragment>
        <div className="profile-detail">Lorem Ipsum is simply dummy text</div>
        <div className="profile-detail">Lorem Ipsum is simply dummy text</div>
        <div className="profile-detail">Lorem Ipsum is simply dummy text</div>
      </React.Fragment>
    );
  }
  getSearchProducts() {
    const url = searchEcommerceProductsUrl(
      this.props.conversation.unique_id,
      this.state.searchText
    );
    this.setState({
      searchText: ''
    });
    this.props.getSearchProducts(url);
  }
  onChange(e) {
    this.setState({ searchText: e.target.value });
  }
  checkKey(e) {
    if (e.target.keyCode === 13) {
      this.getSearchProducts();
    }
  }
  shouldDisplaySearch() {
    if (this.state.showSearch) {
      return (
        <div>
          <input
            className="product_search_box"
            type="text"
            value={this.state.searchText}
            placeholder="enter product name"
            onChange={this.onChange}
            onKeyDown={this.checkKey}
          />
          <input
            type="button"
            value="Submit"
            className="btn btn-default"
            onClick={this.getSearchProducts}
          />
        </div>
      );
    }
    return null;
  }
  shouldDisplayProducts() {
    //debugger;
    if (
      this.props.products &&
      this.props.products.status === 'success' &&
      this.state.ecommerceVariable === 'Search Products'
    ) {
      if (this.props.products.products.length > 0) {
        return this.props.products.products.map(product => {
          return (
            <div className="well product_details">
              {product.name}
              <br />
              <span className="product_description">{product.description}</span>
            </div>
          );
        });
      } else {
        return <span className="products_error_message">no searched products</span>;
      }
    } else if (
      this.props.products &&
      this.state.ecommerceVariable === 'Search Products' &&
      this.props.products.status === 'error'
    ) {
      return <span className="products_error_message">{this.props.products.error}</span>;
    }
    return null;
  }
  shouldDisplayOrders() {
    if (
      this.props.orders !== undefined &&
      this.props.orders.status === 'success' &&
      this.state.ecommerceVariable === 'Customer Orders'
    ) {
      if (this.props.orders.orders.length > 0) {
        return this.props.orders.orders.map(order => {
          const date = new Date(order.createdAt);
          return (
            <div className="well card">
              <div className="order_details">
                {order.id}
                <br />
                {`Rs. ${order.totalPrice}`}
              </div>
              <div className="ordered_time">{`${date.getDate()}/${date.getMonth() +
                1}/${date.getFullYear()}`}</div>
            </div>
          );
        });
      } else {
        return <span className="products_error_message">no orders</span>;
      }
    } else if (this.props.orders !== undefined && this.props.orders.status === 'error') {
      return <span className="products_error_message">{this.props.orders.error}</span>;
    }
    return null;
  }

  visitDetails(curUser, loading) {
    if (loading) return <i className="fa fa-spin fa-spinner" />;

    const sessions = curUser && curUser.sessions;
    if (sessions) {
      let array = Object.values(sessions).map(item => {
        return new Date(item.start_at).getFullYear();
      });
      let arrayMonths = Object.values(sessions).map(item => {
        return new Date(item.start_at).getMonth();
      });
      let d = new Date();
      let diff = Math.abs(arrayMonths[0] - d.getMonth());
      return array[0] === d.getFullYear()
        ? diff == 1
          ? `${diff} month`
          : `${diff} months`
        : `${array[0]}`;
    } else {
      return curUser ? 'New Visit' : <i className="fa fa-spin fa-spinner" />;
    }
  }

  showMinDetails = () => {
    const { conversation, curUser } = this.props;
    return (
      <div className="profile-cart-wrapper">
        <div className="each-customer-item">
          <span className="min-details-head">User Since</span>
          <img
            src={require('../public/icons/calendar.svg')}
            alt=""
            style={{ width: '20px', height: '20px', marginTop: '10px' }}
          />
          <article className="profile-cart-price">
            {this.visitDetails(curUser, this.props.curUserLoading)}
          </article>
        </div>
        <div className="each-customer-item">
          <span className="min-details-head">Total Spend</span>
          <img
            src={require('../public/icons/dollar.png')}
            alt=""
            style={{ width: '20px', height: '20px', marginTop: '10px' }}
          />
          <article className="profile-cart-price">
            {curUser !== null &&
            curUser !== undefined &&
            curUser.orders_summary &&
            curUser.orders_summary.all
              ? conversation.data.cart && conversation.data.cart.currency === 'USD'
                ? `$ ${curUser.orders_summary.all.total_price.toFixed(2)}`
                : curUser.orders_summary.all.total_price.toFixed(2)
              : '$ 0'}
          </article>
        </div>
        <div className="each-customer-item">
          <span className="min-details-head">Total Orders</span>
          <img
            src={require('../public/icons/shoppingBag.svg')}
            alt=""
            style={{ width: '20px', height: '20px', marginTop: '10px' }}
          />
          <article className="profile-cart-price">
            {curUser !== null && curUser !== undefined && curUser.orders
              ? Object.keys(curUser.orders).length
              : 0}{' '}
          </article>
        </div>
      </div>
    );
  };
  handleCartItemSearch = e => {
    this.setState({
      searchCart: e.target.value
    });
  };
  getFilteredCartItems = curUser => {
    let filteredCartItems = (curUser && curUser.cart && curUser.cart.items) || [];
    let allCartItems = (curUser && curUser.cart && curUser.cart.items) || [];
    let cartSearchText = this.state.searchCart;

    if (cartSearchText) {
      filteredCartItems = [];
      allCartItems &&
        allCartItems.length > 0 &&
        allCartItems.forEach(item => {
          if (
            cartSearchText.toLowerCase() ===
            item.title.slice(0, cartSearchText.length).toLowerCase()
          ) {
            filteredCartItems.push(item);
          }
        });
    }
    return filteredCartItems;
  };
  showCartContents = () => {
    const { curUser } = this.props;
    let fileteredCartItems = this.getFilteredCartItems(curUser);
    return (
      <div className="cart-contents">
        <div className="cart-search">
          <input
            type="text"
            placeholder="Search"
            className="form-control"
            onChange={e => this.handleCartItemSearch(e)}
          />
        </div>
        {curUser !== null && fileteredCartItems.length > 0 ? (
          fileteredCartItems.map(eachItem => (
            <div className="each-order-item px-2" key={eachItem.product_id}>
              <div className="order-image">
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={
                    eachItem.picUrl ? eachItem.picUrl : require('../public/icons/shoppingBag.svg')
                  }
                  alt=""
                />
              </div>
              <div className="each-item-details">
                <article className="product-name" style={{ marginBottom: '6px', marginTop: '3px' }}>
                  {eachItem.title}
                </article>
                <article className="product-price">
                  Qty <b style={{ marginRight: '30px' }}>{eachItem.quantity}</b>
                  Price{' '}
                  <b>
                    {curUser.cart.currency === 'USD'
                      ? `$${eachItem.price}`
                      : `${curUser.cart.currency}${eachItem.price}`}
                  </b>
                </article>
              </div>
            </div>
          ))
        ) : (
          <p className="mb-2">
            {this.state.searchCart ? 'No Matches' : 'No items available in the Cart'}
          </p>
        )}
      </div>
    );
  };

  showUserVisits = () => {
    let array = [];
    if (this.props.curUserLoading) return <i className="fa fa-2x fa-spin fa-spinner" />;
    const { curUser } = this.props;
    return (
      <div className="visits-container">
        {curUser !== null &&
        curUser !== undefined &&
        curUser.sessions &&
        Object.values(curUser.sessions).length > 0 ? (
          Object.values(curUser.sessions)
            .reverse()
            .map(item => (
              <div className="each-visit" key={item.start_at}>
                {/* {this.getSession(item.start_at)} */}
                {this.getVisit(item.start_at, true)}
              </div>
            ))
        ) : (
          <p className="mb-2">No Visits till now</p>
        )}
      </div>
    );
  };
  formatDate = (h, m, s) => {
    var dd = 'AM';
    var h2 = h;
    var hour = h;
    if (hour >= 12) {
      hour = h2 - 12;
      dd = 'PM';
    }
    if (hour == 0) {
      hour = 12;
    }
    hour = hour < 10 ? '' + hour : hour;
    var replacement = hour + ':' + m;
    replacement += ' ' + dd;

    return replacement;
  };
  getVisit = (time, visit) => {
    let arrayVisit = [];
    let date = new Date(time).toUTCString();
    arrayVisit = date
      .toString()
      .replace('GMT', '')
      .split(',')[1]
      .split(' ');

    let [hours, minutes, seconds] = arrayVisit[4].split(':');

    if (visit) {
      return (
        <div className="user-visits">
          <span>
            <b style={{ fontSize: '13px' }}>
              {arrayVisit[2]} {arrayVisit[1]}
              {', '}
              {arrayVisit[3]}
            </b>
          </span>
          <span style={{ marginLeft: '30px' }}>{this.formatDate(hours, minutes, seconds)}</span>
        </div>
      );
    } else {
      return (
        <span className="order-text" title="Order Created Date">
          Date:{' '}
          <b>
            {arrayVisit[2]} {arrayVisit[1]}
            {', '}
            {arrayVisit[3]}
          </b>
        </span>
      );
    }
  };
  handleOrderSearch = e => {
    this.setState({ searchOrder: e.target.value });
  };

  getSession = time => {
    let date = new Date(time).toUTCString();
    return date.toString().replace('GMT', '');
  };
  getFilteredOrdersBySearchedText = curUser => {
    let filteredOrders = (curUser && curUser.orders && curUser.orders) || {};
    let allOrders = curUser && curUser.orders && curUser.orders;
    let orderSearchText = this.state.searchOrder;

    if (orderSearchText) {
      filteredOrders = {};
      for (let key in allOrders) {
        let slicedString = allOrders[key].order_id.toString().slice(0, orderSearchText.length);

        if (orderSearchText == slicedString) {
          filteredOrders[key] = allOrders[key];
        }
        allOrders[key].items.forEach(item => {
          if (
            orderSearchText.toLowerCase() ===
            item.title.slice(0, orderSearchText.length).toLowerCase()
          ) {
            filteredOrders[key] = allOrders[key];
            return;
          }
        });
      }
    }
    return filteredOrders;
  };

  showUserOrders = () => {
    if (this.props.curUserLoading) return <i className="fa fa-2x fa-spin fa-spinner" />;

    const { curUser } = this.props;
    let filteredOrders = this.getFilteredOrdersBySearchedText(curUser);

    const isEmpty = value => {
      return Boolean(value && typeof value === 'object') && !Object.keys(value).length;
    };

    return (
      <div className="orders-container">
        <div className="order-search">
          <input
            type="text"
            placeholder="Search Order"
            className="form-control"
            onChange={e => this.handleOrderSearch(e)}
          />
        </div>
        {curUser !== null && curUser !== undefined && curUser.orders && !isEmpty(filteredOrders) ? (
          Object.values(filteredOrders)
            .reverse()
            .map((item, i) => {
              return (
                <div className="each-order" key={Date.now() + item.order_id}>
                  <div className="order-mini-details">
                    <span className="order-subtext" title="Order ID">
                      <b>Order# {item.order_id}</b>
                    </span>
                    {/* <span className="order-value" title="Total Price">
                      {item.currency === 'USD'
                        ? `$${item.total_price}`
                        : `${item.currency}${item.total_price}`}
                    </span> */}
                  </div>
                  <div className="order-mini-details">
                    {item.created_at ? this.getVisit(item.created_at, false) : 'Unknown'}

                    <span className="order-value" title="Total Price">
                      Amount:{' '}
                      <b>
                        {item.currency === 'USD'
                          ? `$${item.total_price}`
                          : `${item.currency}${item.total_price}`}
                      </b>
                    </span>
                  </div>
                  {item.items && item.items.length > 0
                    ? item.items.map(eachItem => (
                        <div className="each-order-item" key={eachItem.product_id}>
                          <div className="order-image">
                            <img
                              style={{ width: '100%', height: '100%' }}
                              src={
                                eachItem.picUrl
                                  ? eachItem.picUrl
                                  : require('../public/icons/shoppingBag.svg')
                              }
                              alt=""
                            />
                          </div>
                          <div className="each-item-details">
                            <article className="product-name">{eachItem.title}</article>
                            <article className="product-price">
                              Qty <b style={{ marginRight: '30px' }}>{eachItem.quantity}</b>
                              Price{' '}
                              <b>
                                {curUser.cart.currency === 'USD'
                                  ? `$${eachItem.price}`
                                  : `${curUser.cart.currency}${eachItem.price}`}
                              </b>
                            </article>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              );
            })
        ) : (
          <p className="mb-2">{this.state.searchOrder ? 'No matches' : 'No Orders available'}</p>
        )}
      </div>
    );
  };
  handleCartClick = e => {
    if (this.state.open) {
      e.target.style.color = 'blue';
    } else {
      e.target.style.color = 'black';
    }
    this.setState({ open: !this.state.open });
  };

  handleCartClick = e => {
    if (this.state.openCart) {
      e.target.style.color = '#7480FF';
    } else {
      e.target.style.color = 'black';
    }
    this.setState({ openCart: !this.state.openCart });
  };

  handleOrderClick = e => {
    if (this.state.openOrder) {
      e.target.style.color = '#7480FF';
    } else {
      e.target.style.color = 'black';
    }
    this.setState({ openOrder: !this.state.openOrder });
  };

  handleVisitClick = e => {
    if (this.state.openVisit) {
      e.target.style.color = '#7480FF';
    } else {
      e.target.style.color = 'black';
    }
    this.setState({ openVisit: !this.state.openVisit });
  };

  collapseCartHead = text => {
    return (
      <div className="collapse-head" onClick={this.handleCartClick}>
        <span className="collapse-head-text">{text}</span>
        {this.state.cartOpening === true ? (
          <img src={require('../public/icons/giphy.gif')} className="collapse-head-loader" alt="" />
        ) : null}
      </div>
    );
  };

  collapseOrdersHead = text => {
    return (
      <div className="collapse-head" onClick={this.handleOrderClick}>
        <span className="collapse-head-text">{text}</span>
        {this.state.ordersOpening === true ? (
          <img src={require('../public/icons/giphy.gif')} className="collapse-head-loader" alt="" />
        ) : null}
      </div>
    );
  };

  collapseVisitsHead = text => {
    return (
      <div className="collapse-head" onClick={this.handleVisitClick}>
        <span className="collapse-head-text ">{text}</span>
        {this.state.visitsOpening === true ? (
          <img src={require('../public/icons/giphy.gif')} className="collapse-head-loader" alt="" />
        ) : null}
      </div>
    );
  };

  handlecartOpening = () => {
    this.setState({ cartOpening: !this.state.cartOpening });
  };

  handlevisitsOpening = () => {
    this.setState({ visitsOpening: !this.state.visitsOpening });
  };

  handleordersOpening = () => {
    this.setState({ ordersOpening: !this.state.ordersOpening });
  };

  render() {
    const { conversation } = this.props;

    return (
      <div className="profile p-3">
        {conversation !== null && conversation !== undefined && conversation.data && (
          <>
            {this.imageDisplay(conversation)}
            {this.showMinDetails()}
          </>
        )}
        {/* {this.isEcommercePresent(conversation)}
         {this.shouldDisplaySearch()}
        {this.shouldDisplayProducts()}
        {this.shouldDisplayOrders()} */}
        <div className="customer-profile-details">
          <Collapsible
            trigger={this.collapseCartHead('Cart')}
            onOpening={this.handlecartOpening}
            onClosing={this.handlecartOpening}
            onOpen={this.handlecartOpening}
            onClose={this.handlecartOpening}
          >
            {this.showCartContents()}
          </Collapsible>
          <Collapsible
            trigger={this.collapseVisitsHead('Visits')}
            onOpening={this.handlevisitsOpening}
            onClosing={this.handlevisitsOpening}
            onOpen={this.handlevisitsOpening}
            onClose={this.handlevisitsOpening}
          >
            {this.showUserVisits()}
          </Collapsible>
          <Collapsible
            trigger={this.collapseOrdersHead('Orders')}
            onOpening={this.handleordersOpening}
            onClosing={this.handleordersOpening}
            onOpen={this.handleordersOpening}
            onClose={this.handleordersOpening}
          >
            <div>{this.showUserOrders()}</div>
          </Collapsible>
        </div>
        {
          <div className="customer-profile-app-store">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=io.autochat.inbox"
                className="app-badge"
              >
                <img src={googlePlayBadge} />
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/us/app/autochat-inbox/id1518996007"
                className="app-badge"
              >
                <img src={appStoreBadge} />
              </a>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    conversation: state.activeConversation.conversation,
    products: state.searchProducts.products,
    orders: state.customerOrders.orders,
    users: state.users.users,
    curUser: state.currentCustomer.curUser,
    curUserLoading: state.currentCustomer.loading
  };
};
export default connect(mapStateToProps, { getSearchProducts, getCustomerOrders })(Profile);
